import { ReactElement, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Preferences } from '@capacitor/preferences';
import { actions, RootState, ThunkDispatchType } from "../../store";
import ResetPassword from "./ResetPassword";
import { Navigate, useLocation } from "react-router-dom";
import { PAGE_URL } from "../../constants";
import { Box } from "@mui/material";
import colors from "../../colors";


export interface ResetPasswordContainerProps {
  email?: string;
}

const ResetPasswordContainer = ({
  email
}: ResetPasswordContainerProps): ReactElement => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const dispatch: ThunkDispatchType = useDispatch();
  const [loading, setLoading] = useState(false);
  const [verifiedUsername, setVerifiedUsername] = useState('');
  const [resetStep, setResetStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [verifiedPassword, setVerifiedPassword] = useState('');
  const [showRequestNewCode, setShowRequestNewCode] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const handleSubmitEmail = async (values: { email: string }) => {
    if (values.email) {
      await dispatch(actions.auth.startForgotPassword(values.email));
      setVerifiedUsername(values.email);
      setErrorMessage([]);
      setResetStep(1);
    }
  };

  const handleResendCode = async () => {
    if (verifiedUsername && !toastMessage) {
      setToastMessage('A new code hase been sent, check your inbox.');
      const data = await dispatch(actions.auth.startForgotPassword(verifiedUsername));
      if (data && 'errorType' in data && data.message) {
        setErrorMessage([data.message]);
      } else {
        setErrorMessage([]);
        setResetStep(1);
      }
    }
  };

  const handleResetPassword = useCallback( async (password: string, code: string) => {
    if (code && password) {
      setLoading(true);
      const data = await dispatch(actions.auth.confirmForgotPassword(verifiedUsername, code, password));
      if (data && 'errorType' in data && data.message && data.errorType) {
        setErrorMessage([data.message]);
        setShowRequestNewCode(true);
        setLoading(false);
      } else {
        setVerifiedPassword(password);
        setShowRequestNewCode(false);
        setErrorMessage([]);
        setLoading(false);
        setResetStep(2);
      }
    }
  }, [dispatch, verifiedUsername]);

  const handleLogin = async () => {
    if (verifiedPassword && verifiedUsername) {
      try {
        const data = (await dispatch(actions.auth.login(verifiedUsername, verifiedPassword, true)));
        if (data?.status === 400) {
          setErrorMessage([data.data["detail"].message]);
        } else if ('onLine' in navigator && !navigator.onLine) {
          setErrorMessage(['Offline - please check your internet connection']);
        } else if (data && 'id' in data) {
          // A valid response, so the email was valid. Save it.
          Preferences.set({key: 'email', value: verifiedUsername});
        }
      } catch (err) {
        if (err instanceof Error) {
          setErrorMessage([err.message]);
        } else {
          setErrorMessage([String(err)]);
        }
      }
    }
  };

  // if you are logged in this will prevent Storybook from showing this component
  if (isAuthenticated) {
    const state = location.state;
    let pathname;
    if (state?.from?.pathname) {
      pathname = state.from.pathname;
    } else {
      pathname = location.pathname !== PAGE_URL.RESET_PASS ? location.pathname : PAGE_URL.DASHBOARD;
    }
    return <Navigate to={pathname} />;
  } 

  return (
    <Box sx={{ backgroundColor: colors.greyBackdrop, height: '100%', width: '100%' }} overflow='auto'>
      <Box height='100%' paddingTop={10}>
        <ResetPassword 
          onSubmitEmail={handleSubmitEmail} 
          onResetPassword={handleResetPassword}
          onResendCode={handleResendCode}
          onLogin={handleLogin}
          submissionErrors={errorMessage} 
          verifiedEmail={verifiedUsername}
          resetStep={resetStep}
          loading={loading} 
          email={email}
          offerNewCode={showRequestNewCode}
          toastMessage={toastMessage}
          onClearToastMessage={() => setToastMessage('')}
        />
      </Box>
    </Box>
  );
};

export default ResetPasswordContainer;